<template>
  <div class="row_contain" style="padding-top: 46px">
    <van-nav-bar
      :fixed="fixed"
      title="我的订单"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="shoppingRow">
      <van-search
        v-model="order_code"
        show-action
        placeholder="请输入订单号或发货单号"
        @search="onSearch"
      >
        <template #action>
          <div @click="onSearch">搜索</div>
        </template>
      </van-search>
      <van-tabs v-model="activeName" sticky @click="onClick">
        <van-tab
          v-for="(i, k) in activeItem"
          :key="k"
          :name="i.name"
          :title="i.title"
        ></van-tab>
        <van-empty description="暂无数据" v-if="list.length == 0" />
        <div v-else>
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            :immediate-check="immediate"
            @load="onLoad"
          >
            <van-skeleton title :row="6" v-if="loadStart" />

            <div class="row" v-for="(i, k) in list" :key="k" v-else>
              <van-cell-group>
                <van-cell title="日期" :value="i.created_at" />
              </van-cell-group>
              <van-card
                v-for="(item, key) in i.goods"
                :key="key"
                :tag="item.goods_code"
                :num="item.num"
                currency="￥"
                :price="item.total_price"
                :desc="item.goods_desc"
                :title="item.goods_title"
                :thumb="item.goods_pic"
              >
                <template #tags>
                  <van-tag plain type="danger">{{ item.tag_name }}</van-tag>
                </template>
              </van-card>
              <van-cell-group>
                <van-cell center title="收货人信息" v-if="i.code_pic">
                  <template #right-icon>
                    <van-image width="100" height="100" :src="i.code_pic" />
                  </template>
                </van-cell>
                <van-cell
                  v-else
                  center
                  title="收货人信息"
                  :value="
                    '(姓名:' +
                    i.lastname +
                    ' ' +
                    i.name +
                    ' ' +
                    ')(住所1都道府县:' +
                    i.province +
                    ' ' +
                    ')(详细地址2:' +
                    i.city +
                    ' ' +
                    ')(详细地址3:' +
                    i.address +
                    ' ' +
                    ')(详细地址4:' +
                    i.address2 +
                    ' ' +
                    ')(邮编:' +
                    i.code +
                    ')'
                  "
                />

                <van-cell title="订单号" :value="i.order_code || '无'" />
                <van-cell
                  title="发货单号"
                  :value="i.express_num"
                  v-if="i.express_num"
                />
                <van-cell title="备注" :value="i.node || '无'" />
                <van-cell
                  title="总金额"
                  :value="'￥' + i.total_price"
                  value-class="money"
                />
                <van-cell
                  title="异常件理由"
                  :value="i.parts_node || '无'"
                  v-if="i.status == '7'"
                />
              </van-cell-group>
              <van-steps
                direction="vertical"
                v-if="i.msg_info.length > 0"
                :active="i.msg_info.length - 1"
              >
                <van-step v-for="(ine, ky) in i.msg_info" :key="ky">
                  <h4 style="margin: 0">驳回理由：{{ ine.reject_node }}</h4>
                  <p>{{ ine.created_at }}</p>
                </van-step>
              </van-steps>
              <div class="footerBtn">
                <div v-if="i.status != '1' && i.status != '2'"></div>
                <div
                  class="van-sku-actions"
                  v-if="i.status == '2' && i.is_examine == '1'"
                >
                  <button
                    class="van-button van-button--large"
                    :data-id="i.order_no"
                    @click="qixiao"
                  >
                    <div class="van-button__content">
                      <van-icon name="qxiao" /><span class="van-button__text"
                        >取消订单</span
                      >
                    </div>
                  </button>
                </div>
                <div class="van-sku-actions" v-if="i.status == '1'">
                  <button
                    class="van-button van-button--warning van-button--large"
                    :data-id="i.order_no"
                    @click="delet"
                  >
                    <div class="van-button__content">
                      <van-icon name="delete" /><span class="van-button__text"
                        >删除</span
                      >
                    </div>
                  </button>
                  <button
                    class="van-button van-button--danger van-button--large"
                    :data-id="i.order_no"
                    @click="pay"
                  >
                    <span class="van-button__text">去支付</span>
                  </button>
                </div>
                <h3 v-if="i.status == '1'" style="color: red">未支付</h3>
                <h3
                  v-if="i.status == '2' && i.is_examine == '1'"
                  style="color: blue"
                >
                  已支付
                </h3>
                <h3
                  v-if="i.status == '2' && i.is_examine == '2'"
                  style="color: blue"
                >
                  待发货
                </h3>
                <h3
                  v-if="i.status == '3' || i.status == '4'"
                  style="color: #ffd01e"
                >
                  已完成
                </h3>
                <h3 v-if="i.status == '5'" style="color: #ccc">退款中</h3>
                <h3 v-if="i.status == '6'" style="color: #333">退款已完成</h3>
                <h3 v-if="i.status == '7'" style="color: red">
                  异常件处理(退回积分{{ i.total_price }})
                </h3>
              </div>
            </div>
          </van-list>
        </div>
      </van-tabs>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {}
  },
  data () {
    return {
      title: '',
      order_code: '',
      loadStart: true,
      fixed: true,
      activeItem: [{
        name: 'all',
        title: '全部'
      }, {
        name: 'payment',
        title: '未支付'
      }, {
        name: 'delivery',
        title: '已支付'
      }, {
        name: 'delivery',
        title: '待发货'
      }, {
        name: 'complete',
        title: '已完成'
      }, {
        name: 'refund',
        title: '退款订单'
      }, {
        name: 'abnormal',
        title: '异常件'
      }],
      list: [],
      page: 1,
      immediate: false,
      activeName: 'all',
      disabled: true,
      loading: false,
      finished: false,
    }
  },
  created () {
    this.activeName = this.id;
    this.get_index('加载', this.typeAll(this.id))
  },
  methods: {
    onSearch () {

      this.page = 1;
      this.finished = false;
      this.loadStart = true;
      this.activeName = 'all';
      this.get_index('加载', this.typeAll(this.activeName))

    },
    onClickLeft () {
      this.$router.back()
    },
    typeAll (name) {
      let num = '0';
      switch (name) {
        case 'all':
          num = '0';
          break;
        case 'payment':
          num = '1';
          break;
        case 'delivery':
          num = '2';
          break;
        case 'complete':
          num = '4';
          break;
        case 'refund':
          num = '5';
          break;
        case 'abnormal':
          num = '7';
          break;
      }
      return num
    },
    async get_index (start, typeStatus) {
      let res = await this.$http.get('user/get_order_list', {
        params: {
          user_id: this.$store.state.login.userInfo.user_id,
          status: typeStatus,
          page: this.page,
          order_code: this.order_code
        }
      })
      let arr = res.data.data;
      if (start == '加载') {
        if (this.title == '待发货') {
          arr = arr.filter(item => {
            return item.is_examine == '2' ? item : ''
          })
        }
        this.list = arr;
        console.log('as', this.list, arr)
      }
      if (arr.length > 0) {
        if (start == '分页') {
          if (this.title == '待发货') {
            arr = arr.filter(item => {
              return item.is_examine == '2' ? item : ''
            })
          }
          arr.forEach(item => {
            this.list.push(item)
          });
        }
        this.finished = false;
      } else {

        this.finished = true;
      }
      this.loading = false;
      this.loadStart = false;
    },
    async onLoad () {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      // 加载状态结束
      this.loading = true;
      setTimeout(() => {
        this.page += 1
        this.get_index('分页', this.typeAll(this.activeName))
      }, 1000)
    },
    qixiao (ev) {
      let no = ev.target.dataset.id;
      this.$Dialog.confirm({
        title: '取消提示',
        message: '是否申请取消该订单',

      })
        .then(() => {
          this.$Toast.loading({ forbidClick: true });
          this.$http.get('order/order_refund', {
            params: {
              no: no,
              user_id: this.$store.state.login.userInfo.user_id
            }
          }).then(res => {
            if (res && res.data.error_code == 0) {
              this.page = 1;
              this.loading = false;
              this.finished = false;
              this.loadStart = true;
              this.activeName = 'refund';
              this.get_index('加载', this.typeAll(this.activeName));
              this.$Toast.clear();
              this.$Toast(res.data.error_msg)
            }

          })

        })
        .catch(() => {
          // on cancel
        });
    },
    pay (ev) {
      let no = ev.target.dataset.id;
      this.$Dialog.confirm({
        title: '支付提示',
        message: '支付订单',

      })
        .then(() => {
          this.$Toast.loading({ forbidClick: true });
          this.$http.get('order/order_pay', {
            params: {
              no: no,
              user_id: this.$store.state.login.userInfo.user_id
            }
          }).then(res => {
            if (res && res.data.error_code == 0) {
              this.page = 1;
              this.loading = false;
              this.finished = false;
              this.loadStart = true;
              this.activeName = 'delivery';
              this.get_index('加载', this.typeAll(this.activeName));
              this.$Toast.clear();
              this.$Toast(res.data.error_msg)
            }

          })

        })
        .catch(() => {
          // on cancel
        });
    },
    delet (ev) {
      console.log(ev.target.dataset.id)
      let no = ev.target.dataset.id;
      this.$Dialog.confirm({
        title: '删除提示',
        message: '删除订单',

      })
        .then(() => {
          this.$Toast.loading({ forbidClick: true });
          this.$http.get('order/order_delete', {
            params: {
              no: no,
              user_id: this.$store.state.login.userInfo.user_id
            }
          }).then(res => {
            if (res && res.data.error_code == 0) {

              this.list = this.list.filter(item => {
                return item.order_no != no
              })
              this.$Toast.clear();
              this.$Toast(res.data.error_msg)
            }

          })

        })
        .catch(() => {
          // on cancel
        });
    },
    onClick (name, title) {
      console.log(name, title);
      this.page = 1;
      this.loading = false;
      this.finished = false;
      this.loadStart = true;
      this.title = title;
      this.get_index('加载', this.typeAll(this.activeName))
    }
  }

}
</script>

<style lang="less" scoped>
.money {
  font-size: 18px;
  color: red;
}
.footerBtn h3 {
  margin: 0;
}
.row {
  width: 100%;
  background: white;
  margin-top: 10px;
}
.van-button {
  min-width: 100px;
}
.van-submit-bar {
  position: absolute;
}
.shoppingRow {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.van-card__thumb {
  width: 120px;
  height: 120px;
}
.van-card__content {
  padding-right: 10px;
}
.van-card__footer {
  padding: 5px 0;
  border-top: 1px dashed #c9c9c9;
}
.van-card {
  background: #fff;
}
.footerBtn {
  display: flex;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
}
.van-sku-actions {
  padding: 0;
}
</style>